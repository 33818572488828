import React, {useState} from 'react';
import { Helmet } from "react-helmet";

// Components
import Header from '../../components/header';
import Footer from '../../components/footer';
import SmoothScroll from "../../components/smooth-scroll/smooth-scroll";
import Modal from "../../components/modal";
import NavMenu from "../../components/nav-menu";

// Containers
import HeroContainer from '../hero/view';
import TourContainer from '../tour/view';
import BusinessView from '../business/view';
import NetworkView from '../network/view';
import SectionView from '../section/view';
import ProductionView from '../production/view';
import VentureView from '../venture/view';
import JoinForm from "../../components/join-form";

// Utils
import ScrollHandler from "../../utils/scrollHandler";

// Hooks
import useMeta from "../../hooks/ui/useMeta";

function HomeView () {
  const { title, description, oggDescription, oggImage, oggTitle } = useMeta();
  const [showModal, setShowModal] = useState(false);
  
  return (
    <div className='main'>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta property="og:title" content={oggTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={oggDescription} />
        <meta property="og:image" content={oggImage.url} />
      </Helmet>
      
      <NavMenu/>
      <SmoothScroll>
        <ScrollHandler />
        <Header />
        <HeroContainer />
        <TourContainer />
        <BusinessView />
        <SectionView />
        <NetworkView />
        <ProductionView />
        <VentureView />
        <Footer setShowModal={setShowModal}/>
       </SmoothScroll>

      <Modal showModal={showModal}>
        <JoinForm setShowModal={setShowModal} />
      </Modal>
    </div>
  );
}

export default HomeView;
