import React, { useRef, useState, useCallback, useLayoutEffect } from "react"
import ResizeObserver from "resize-observer-polyfill"
import {
	useViewportScroll,
	useTransform,
	useSpring,
	motion
} from "framer-motion"

const SmoothScroll = ({ children }) => {
	// scroll container
	const scrollRef = useRef(null)
	
	// mobile view
	const mobileWith = window.innerWidth;
	// page scrollable height based on content length
	const [pageHeight, setPageHeight] = useState(0)
	
	// update scrollable height when browser is resizing
	const resizePageHeight = useCallback(entries => {
		for (let entry of entries) {
			setPageHeight(entry.contentRect.height)
		}
	}, [])
	
	// observe when browser is resizing
	useLayoutEffect(() => {
		const resizeObserver = new ResizeObserver(entries =>
			resizePageHeight(entries)
		)
		scrollRef && resizeObserver.observe(scrollRef.current)
		return () => resizeObserver.disconnect()
	}, [scrollRef, resizePageHeight])
	
	const { scrollY } = useViewportScroll()
	const transform = useTransform(scrollY, [0, pageHeight], [0, -pageHeight])
	const physics = {
		...(mobileWith <= 470 && { damping: 2, mass: 0.05, stiffness: 75 }),
		...(mobileWith >= 470 && { damping: 15, mass: 0.37, stiffness: 35 })
	} // easing of smooth scroll
	const spring = useSpring(transform, physics) // apply easing to the negative scroll value
	
	return (
		<>
			<motion.div
				ref={scrollRef}
				style={{ y: spring }} // translateY of scroll container using negative scroll value
				className="scroll-container"
			>
				{children}
			</motion.div>
			<div style={{ height: pageHeight }} />
		</>
	)
}

export default SmoothScroll
