import React from 'react';

// Components
import Button from '../../components/button';
import useSection from "../../hooks/ui/useSection";

function SectionView () {
  const {
    title,
    subtitle,
    description,
    descriptionSecond,
    imageAlt,
    image,
    buttonText,
    buttonLink,
    anchor
  } = useSection();

  const accentColor = '#fafafa';
  const innerColor = '#e4e3e3';
  const buttonColor = '#474646';

  return (
    <section className={'lol-network section'} style={{ backgroundColor: accentColor}} id={`#${anchor.toLowerCase()}`}>
      <h2 className={'title'}>{title}</h2>
      <div className={'section_inner'} style={{ backgroundColor: innerColor}}>
        <h6 className={'subtitle'}>{subtitle}</h6>

        <div className={'lol-network-show'}>

          <img className={'lol-network-show_pic'} src={image.url} alt={imageAlt} />

          <div className={'lol-network-show_more'}>

            <div className={'description'}>
              <p>{description}</p>
              <p>{descriptionSecond}</p>
            </div>

            <Button url={buttonLink} buttonColor={buttonColor} buttonText={buttonText} />
          </div>

        </div>
      </div>
    </section>
  );
}

export default SectionView;
