import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Utils
import { Logo } from '../../utils/svgIcons';

// Hooks
import useHeader from "../../hooks/ui/useHeader";
import useFooter from "../../hooks/ui/useFooter";

function NavMenu() {
  const { logo, links } = useHeader();
  const { socialLinks } = useFooter();
  
  const [sticky, setSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [lastYPos, setLastYPos] = useState(0);
  const [shouldShowActions, setShouldShowActions] = useState(false);

  const headerScroll = () => {
    if (window.scrollY <= 275) {
        setSticky(false);
    } else if (window.scrollY >= 350) {
      setSticky(true);
    } else if (window.scrollY >= 750) {
      setSticky(false);
    }
  };
  
  const track = () => {
    window.comtrack();
  }

  useEffect(() => {
    if(window.innerWidth >= 430 ) {
      window.addEventListener('scroll', headerScroll);
      return () => window.removeEventListener('scroll', headerScroll);
    }
  }, []);

  useEffect(() => {
    let timer;

    function handleNavScroll() {
      const yPos = window.scrollY;
      const isScrollingUp = yPos < lastYPos;

      timer = setTimeout(() => {
        setShouldShowActions(!isScrollingUp);
        setLastYPos(yPos);
      }, 550);
    }

    window.addEventListener("scroll", handleNavScroll, false);

    return () => {
      window.removeEventListener("scroll", handleNavScroll, false);
      clearTimeout(timer);
    };
  }, [lastYPos]);
  
  return (
    <div className={`${sticky ? 'header nav scrolled' : 'header nav'} ${isOpen ? 'is-opened' : ''}`}>

      <nav className={`menu ${shouldShowActions ? 'show' : ''}`} 
        onMouseOver={() => setShouldShowActions(true)}>
        <div className={'menu_logo'}>
          <Link to={"/"}>{logo}</Link>
        </div>
        <ul>
          {links.map((links, idx) => {
            const {link, url } = links.fields;
              return (
                <li key={idx}>
                  {url ?
                    <a href={url} target='_blank' rel="noopener noreferrer">{link}</a>
                    :
                    <Link to={`/#${link.toLowerCase()}`}>{link}</Link>
                  }
                </li>
              )
          }
          )}
        </ul>
      </nav>

      <nav className={'mobile-menu'}>
        <div className='header_logo'>
          <Logo color={`${isOpen ? '#FFF' : '#000'}`}/>
        </div>
        <div className="menu-burger" onClick={() => setIsOpen(!isOpen)}>
          {`${isOpen ? 'CLOSE' : 'MENU'}`}
        </div>
    
        <div className="menu-bg">
          <ul className={'nav'}>
            {links.map((links, idx) => {
              const {link, url } = links.fields;
              return (
                <li key={idx}>
                  {url ?
                    <a href={url} target='_blank' rel="noopener noreferrer">{link}</a>
                    :
                    <Link to={`/#${link.toLowerCase()}`} onClick={() => {
                      setIsOpen(!isOpen);
                      track();
                    }}>{link}</Link>
                  }
                </li>
                )
              }
            )}
          </ul>

          <ul className={'social'}>
          {socialLinks.map((item, idx) => <li key={idx}><a href={item.fields.link} target='_blank' rel="noopener noreferrer">{item.fields.title}</a></li>)}
        </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavMenu;
