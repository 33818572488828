import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function useRichText() {
  const parseBodyText = (body) => {
    const parsedText = documentToReactComponents(body);

    return parsedText;
  };

  return {
    parseBodyText
  };
}
