import React from 'react';

//hooks
import useWindowSize from "../../hooks/ui/useWindowSize";

function Image({ tails, desktopImage, mobileImage, altText }) {
  const { width } = useWindowSize();

  const isMobile = width <= 768;

  return isMobile ? (
    <img className={`${tails}`} src={mobileImage} alt={altText} />
  ) : (
    <img className={`${tails}`} src={desktopImage} alt={altText} />
  );
}
export default Image;