import useContentful from '../api/useContentful';

export default function useHero() {
  const { data, isLoading } = useContentful('3si9wxwo79SpBawmCwXD2Q');
  const image = data?.image.fields.file || '';
  const mobileImage = data?.mobileImage.fields.file || '';
  const imageAlt = data?.image.fields.title || '';
  const desktopVideo = data?.desktopVideo.fields.file || '';
  const mobileVideo = data?.mobileVideo.fields.file || '';
  const switchMedia = data?.showVideoLoop || null;
  
  return {
    image,
    mobileImage,
    imageAlt,
    desktopVideo,
    switchMedia,
    mobileVideo,
    isLoading
  };
}
