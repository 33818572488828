import React from 'react';
import logo from '../../assets/images/img-4114@3x.png';

function JoinForm({setShowModal}) {
    return (
      <div className={'join-form'}>
          <div className={'join-form_body'}>
            <div className={'image-wrapper'} style={{ backgroundImage: `url(${logo})` }} />
              <iframe
                width={'100%'}
                height={'100%'}
                className="iframe"
                loading='eager'
                title="form sg"
                src="https://cdn.forms-content.sg-form.com/bd04c2ad-89c3-11ec-a2c4-2ecde2def033"
                scrolling="no" />
            </div>
          <button className={'join-form_btn'} onClick={() => setShowModal(false)}>close</button>
      </div>
    )
}

export default JoinForm;
