import React from 'react';

// Components
import Button from '../../components/button';

// Hooks
import useVentures from "../../hooks/ui/useVentures";
import useRichText from "../../hooks/api/useRichText";

function VentureView () {
  const { title, description, image, anchor, imageAlt, buttonText, buttonLink, subtitle } = useVentures();
  const { parseBodyText } = useRichText();

  const accentColor = '#fafafa';
  const innerColor = '#e4e3e3';
  const buttonColor = '#474646';

  return (
    <section className={'venture section'} style={{ backgroundColor: accentColor}} id={`#${anchor.toLowerCase()}`}>
      <h2 className={'title'}>{title}</h2>
      <div className={'section_inner'} style={{ backgroundColor: innerColor}}>
        <h6 className={'subtitle'}>{subtitle}</h6>

        <div className={'venture-show'}>

          <img className={'venture-show_pic'} src={image.url} alt={imageAlt} />
          <div className={'venture-show_more'}>

            <div className={'description'}>
              {parseBodyText(description)}
            </div>

            <Button url={buttonLink} buttonColor={buttonColor} buttonText={buttonText} />
          </div>

        </div>
      </div>
    </section>
  );
}

export default VentureView;
