import useContentful from '../api/useContentful';

export default function useBusiness() {
  const { data, isLoading } = useContentful('5Eh1tyPV0rcNgf3FHg3aah');
  const title = data?.title || '';
  const anchor = data?.anchor || '';
  const description = data?.description || '';
  const businessItems = data?.businessItems || [];
  
  return {
    title,
    anchor,
    description,
    businessItems,
    isLoading
  };
}
