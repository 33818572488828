import { createClient } from 'contentful';

let client = ''

if(process.env.REACT_APP_CONTENTFUL_ENV === 'dev') {
	client = createClient({
		space: process.env.REACT_APP_SPACE_ID,
		environment: 'dev',
		accessToken: process.env.REACT_APP_ACCESS_TOKEN
	});
} else {
	client = createClient({
		space: process.env.REACT_APP_SPACE_ID,
		environment: 'master',
		accessToken: process.env.REACT_APP_ACCESS_TOKEN
	});
}

export default client;