import React from 'react';

//hooks
import useWindowSize from "../../hooks/ui/useWindowSize";

function Timetable({ dates }) {
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  let formattedEvents = [];
  
  dates.map((item, idx) => {
    const { title, date, hallName, link } = item.fields;
    let { year } = item.fields;

    if (date !== undefined) {
      var obj = {};
      obj["title"] = title;
      obj["hallName"] = hallName;
      obj["link"] = link;

      const dateArr = date.split(" ");
      const month = dateArr[0];
      let day = dateArr[1].slice(0, -1);
      day = day.replace(/\D/g,'');

      if (year == undefined) {
        year = 2022;
      }

      const monthNum = new Date(Date.parse(month + " " + day + ", " + year)).getMonth();
      const d = new Date(year, monthNum, day, 0, 0, 0, 0);

      obj["date"] = date;
      obj["dateSort"] = d;

      formattedEvents.push(obj);
    }
  });

  formattedEvents = formattedEvents.sort(function (a, b) {
    var dateA = new Date(a.dateSort).getTime();
    var dateB = new Date(b.dateSort).getTime();
    return dateA < dateB ? -1 : 1; // ? -1 : 1 for ascending/increasing order
  });

  if (formattedEvents.length == 0) {
    return null;
  }

  return (
    <div className={'timetable'}>
      <div className={'timetable-body'}>
        {
          formattedEvents.map((item, idx) => {
            const { title, date, hallName, link, dateSort } = item;
            let isExpired = false;
            const d = dateSort;
            
            const today = new Date();
            today.setDate(today.getDate()-1);
            today.setHours(0,0,0,0);

            if (d <= today) {
              isExpired = true;
            }

            return (
              <div className={`timetable-row ${isExpired ? 'hide' : ''}`} key={idx}>
                <div className={'timetable-body-cell'}>{date}</div>
                <div className={'timetable-body-cell'}>
                  {title}
                  {isMobile ? 
                    <div className={'hallName'}>{hallName}</div>
                    :
                    ''
                  }                  
                </div>
                <div className={'timetable-body-cell'}>{hallName}</div>
                <div className={'timetable-body-cell'}>
                  {isMobile ? 
                    <a className={link ? '' : 'more-space'} href={link} target='_blank' rel="noopener noreferrer">{link ? `Tickets` : 'SOLD OUT'}</a>
                    :
                    <a className={link ? '' : 'more-space'} href={link} target='_blank' rel="noopener noreferrer">{link ? `Get Tickets` : 'SOLD OUT'}</a>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default Timetable;
