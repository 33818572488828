import React from 'react';

// Components
import Button from '../../components/button';
import useNetwork from "../../hooks/ui/useNetwork";

function NetworkView () {
  const {
    title,
    subtitle,
    description,
    descriptionSecond,
    imageAlt,
    image,
    buttonText,
    buttonLink,
    anchor
  } = useNetwork();

  const accentColor = '#02fb70';
  const innerColor = '#33bd70';
  const buttonColor = '#2b8d56';

  return (
    <section className={'network section'} style={{ backgroundColor: accentColor}} id={`#${anchor.toLowerCase()}`}>
      <h2 className={'title'}>{title}</h2>
      <div className={'section_inner'} style={{ backgroundColor: innerColor}}>
        <h6 className={'subtitle'}>{subtitle}</h6>

        <div className={'network-show'}>

          <img className={'network-show_pic'} src={image.url} alt={imageAlt} />

          <div className={'network-show_more'}>

            <div className={'description'}>
              <p>{description}</p>
              <p>{descriptionSecond}</p>
            </div>

            <Button url={buttonLink} buttonColor={buttonColor} buttonText={buttonText} />
          </div>

        </div>
      </div>
    </section>
  );
}

export default NetworkView;
