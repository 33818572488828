import React from 'react';

//hooks
import useWindowSize from "../../hooks/ui/useWindowSize";
import { OverlayVideo } from '../../components/media-player/media-overlay';

function MediaPlayer({ desktopVideo, mobileVideo }) {
  const { width } = useWindowSize();

  const isMobile = width <= 768;

  return isMobile ? (
    <div className={'wrap-player'}>
      <video
        playsInline
        autoPlay
        muted
        loop
        preload="auto"
        className={'wrap-player_video'}>
        <source src={mobileVideo} type="video/mp4" />
        Your browser does not support the video tag. Please upgrade your browser.
      </video>
      <OverlayVideo />
    </div>
  ) : (
      <div className={'wrap-player'}>
        <video
          playsInline
          autoPlay
          muted
          loop
          preload="auto"
          className={'wrap-player_video'}>
          <source src={desktopVideo} type="video/mp4" />
          Your browser does not support the video tag. Please upgrade your browser.
        </video>
        <OverlayVideo />
    </div>
  );
}
export default MediaPlayer;