import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

function Modal({ children, showModal }) {
	
	const variants = {
		visible: { opacity: 1, transition: { delay: 0.150 } },
		hidden: { opacity: 0 },
		exit: { opacity: 0 }
	}
	
	return (
		<AnimatePresence exitBeforeEnter>
			{showModal ?
			<motion.div
				variants={variants}
				initial="hidden"
				animate="visible"
				exit="hidden"
				className={'modal'}>
				{children}
			</motion.div>
				:
				null
			}
		</AnimatePresence>
	)
}

export default Modal;
