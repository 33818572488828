import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

//utils
import useWindowSize from "../hooks/ui/useWindowSize";

const ScrollHandler = ({ location }) => {
  const { width } = useWindowSize();
  useEffect(() => {
    const element = document.getElementById(location.hash);

    setTimeout(() => {
      if (width <= 768) {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop - 80 : 0
        });
      } else {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element && element.offsetTop
        });
      }
    }, 1000);
  }, [location, width]);

  return null;
};

ScrollHandler.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string
  }).isRequired
};

export default withRouter(ScrollHandler);