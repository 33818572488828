import useContentful from '../api/useContentful';

export default function useTourDates() {
  const { data, isLoading } = useContentful('5N0VXei5CoYo9KSaj0ET0J');
  const title = data?.title || '';
  const anchor = data?.anchor || '';
  const description = data?.description || '';
  const image = data?.image || '';
  const logo = data?.logo || '';
  const mobileImage = data?.mobileImage || '';
  const dates = data?.dates || [];
  
  return {
    title,
    anchor,
    logo,
    dates,
    description,
    image,
    mobileImage,
    isLoading
  };
}
