import useSWR, { cache } from 'swr';
import fetcher from '../../contentful/fetcher';

export default function useContentful(key) {
    const { data, error } = useSWR(key, fetcher, {
        revalidateOnMount: !cache.has(key)
    });

    const isLoading = !data && !error;

    return {
        isLoading,
        data,
        error
    };
}
