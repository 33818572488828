import useContentful from '../api/useContentful';

export default function useFooter() {
  const { data, isLoading } = useContentful('1qNcGH2pMcbKNTILZ45fOH');
  const title = data?.logo || '';
  const newsletterButton = data?.newsletterButton || '';
  const privacyLink = data?.privacyLink || '';
  const termsLink = data?.termsLink || '';
  const copyright = data?.copyright || '';
  const socialLinks = data?.links || [];
  
  return {
    title,
    newsletterButton,
    socialLinks,
    privacyLink,
    termsLink,
    copyright,
    isLoading
  };
}
