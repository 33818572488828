import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// Utils
import { Logo } from '../../utils/svgIcons';

// Hooks
import useHeader from "../../hooks/ui/useHeader";

function Header() {
  const { logo, links } = useHeader();

  useEffect(() => {
    setTimeout(function () {     
      if (window.location.hash === '#tour%20dates') {
        const element = document.getElementById('tour-dates');
        if (element) {
          element.click();
        }
      }
    }, 1000);
  }, []);

  const track = () => {
    window.comtrack();
  }

  return (
    <header className={'header'}>
      <div className='header_logo'>
        <Logo color={'#000'}/>
      </div>

      <nav className={'menu'}>
        <div className={'menu_logo'}>
          <Link to={"/"}>{logo}</Link>
        </div>
        <ul>
          {links.map((linkItem, idx) => {
            const { link, url } = linkItem.fields;
            return (
              <li key={idx}>
                {url ?
                  <a href={url} target='_blank' rel="noopener noreferrer">{link}</a>
                  :
                  <Link
                    to={`/#${link.toLowerCase()}`}
                    id={`${link.toLowerCase().replace(/ /g, "-")}`}
                    className={`${link.toLowerCase().replace(/ /g, "-")}`}
                    onClick={track}
                  >
                    {link}
                  </Link>
                }
              </li>
            )
          })}
        </ul>
      </nav>
    </header>
  );
}

export default Header;