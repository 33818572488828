import React from 'react';

//utils
import { Logo } from '../../utils/svgIcons';

// Hooks
import useFooter from "../../hooks/ui/useFooter";

function Footer({setShowModal}) {
  const { socialLinks, copyright, privacyLink, termsLink, newsletterButton, } = useFooter();
  
  return (
    <footer className={'footer'}>
      <button className={'newsletter-btn'} onClick={() => setShowModal(true)}>{newsletterButton}</button>
      <ul className={'footer-social'}>
        {socialLinks.map((item, idx) => <li key={idx}><a href={item.fields.link} target='_blank' rel="noopener noreferrer">{item.fields.title}</a></li>)}
      </ul>

      <Logo color={'#ffffff'} />

      <div className={'footer-copyright'}>
        <span>{copyright}</span>
        <a href={privacyLink} target='_blank' rel="noopener noreferrer">Privacy</a>
        <a href={termsLink} target='_blank' rel="noopener noreferrer">Terms</a>
      </div>
    </footer>
  );
}

export default Footer;
