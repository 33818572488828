import useContentful from '../api/useContentful';

export default function useHeader() {
  const { data, isLoading } = useContentful('4caP2EY4wXDDLWd7Eu7CV9');
  const logo = data?.logo || '';
  const links = data?.links || [];
  
  return {
    logo,
    links,
    isLoading
  };
}
