import React from 'react';

// Components
import Button from '../../components/button';
import Image from '../../components/image/image';
import Timetable from '../../components/timetable';

// Hooks
import useTourDates from "../../hooks/ui/useTourDates";

function TourView () {
  const { title, anchor,  description, image, dates, logo, mobileImage } = useTourDates();
  const accentColor = '#E39241';
  const innerColor = '#C1772D';

  const buttonLink = 'https://www.ticketmaster.com/kevin-hart-tickets/artist/1057637';
  const buttonColor = '#000';
  const buttonText = 'Buy Tickets';
  
  return (
    <section className={'tour section'} style={{ backgroundColor: accentColor }} id={`#${anchor.toLowerCase()}`}>
      <h2 className={'title'}>{title}</h2>
      <div className={'section_inner'} style={{ backgroundColor: innerColor}}>
        <h6 className={'subtitle'}>
          {description}
          <Button url={buttonLink} buttonColor={buttonColor} buttonText={buttonText} altText={title} />
        </h6>

        <div className={'tour-show'}>
          <Image tails={'tour-show_bg'} desktopImage={image.fields?.file.url} mobileImage={mobileImage.fields?.file.url} />

          { logo && <img className={'tour-show_logo'} src={logo.fields?.file.url} alt={image.fields.title} /> }

          <div className={'tour-schedule'}>
            <Timetable dates={dates} />
          </div>

        </div>
      </div>
    </section>
  );
}

export default TourView;
