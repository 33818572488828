import useContentful from '../api/useContentful';

export default function useMeta() {
  const { data, isLoading } = useContentful('3Vd8f83MLYL1PpJUmKVlg3');
  const title = data?.title || '';
  const description = data?.title || '';
  const oggTitle = data?.oggTitle || '';
  const oggDescription = data?.oggDescription || '';
  const oggImage = data?.oggImage.fields.file || '';
  
  return {
    title,
    description,
    oggTitle,
    oggDescription,
    oggImage,
    isLoading
  };
}
