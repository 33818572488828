import useContentful from '../api/useContentful';

export default function useVentures() {
  const { data, isLoading } = useContentful('5odJIz2j0KVuTRxJF2CxQF');
  const title = data?.title || '';
  const anchor = data?.anchor || '';
  const subtitle = data?.subtitle || '';
  const buttonText = data?.buttonText || '';
  const buttonLink = data?.buttonLink || '';
  const description = data?.description || '';
  const image = data?.image.fields.file || '';
  const imageAlt = data?.image.fields.title || '';
  
  return {
    title,
    anchor,
    subtitle,
    description,
    image,
    imageAlt,
    buttonText,
    buttonLink,
    isLoading
  };
}
