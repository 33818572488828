import React from 'react';

// Hooks
import useBusiness from "../../hooks/ui/useBusiness";

function BusinessView () {
  const { title, description, anchor, businessItems } = useBusiness();

  const accentColor = '#2c46ed';
  const innerColor = '#223194';
  const tabsColor = '#121b51';

  return (
    <>
      {anchor.toLowerCase() === 'business' ? null : <div id={`#business`}></div>}
      <section className={'business section'} style={{ backgroundColor: accentColor }} id={`#${anchor.toLowerCase()}`}>
        <h2 className={'title'}>{title}</h2>
        <div className={'section_inner'} style={{ backgroundColor: innerColor}}>
          <h6 className={'subtitle'}>{description}</h6>

          <div className={'business-tabs'}>
            <ul>
              {businessItems.map((item, idx) => {
                const { title, link, image, description, showVideoLoop } = item.fields;

                return (
                  <li key={idx} style={{ backgroundColor: tabsColor }}>
                    <h6>{title}</h6>
                    <a href={link} target={`${link ? '_blank' : ''}`} rel="noopener noreferrer" className={`${link ? 'tab-pic' : 'tab-pic events-none'}`}>
                      {!showVideoLoop 
                        ? 
                        <img src={image.fields.file.url} alt={image.fields.title} />
                        :
                        <video playsInline autoPlay muted loop preload="auto" className={'wrap-player_video'}>
                          <source src={image.fields.file.url} type="video/mp4" />
                          Your browser does not support the video tag. Please upgrade your browser.
                        </video>
                      }
                      <span>{description}</span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default BusinessView;
