import React from 'react';

import MediaPlayer from '../../components/media-player/media-player';
import Image from '../../components/image/image';

// Hooks
import useHero from "../../hooks/ui/useHero";

function HeroView () {
  const {
    image,
    mobileImage,
    imageAlt,
    switchMedia,
    desktopVideo,
    mobileVideo
  } = useHero();
  
  return (
    <section className={'hero'}>
      {
        switchMedia
          ? <MediaPlayer desktopVideo={desktopVideo.url} mobileVideo={mobileVideo.url} />
          : <Image tails={'hero_pic'} desktopImage={image.url} mobileImage={mobileImage.url} altText={imageAlt} />
      }
    </section>
  );
}

export default HeroView;
