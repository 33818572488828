import React from 'react';

//components
import Button from '../../components/button';
import Image from '../../components/image/image';
import useProduction from "../../hooks/ui/useProduction";
import useRichText from "../../hooks/api/useRichText";


function ProductionView () {
  const {title, subtitle, imageAlt, image, mobileImage, anchor, buttonLink, buttonText, description } = useProduction();
  const { parseBodyText } = useRichText();
  
  const accentColor = '#ff0000';
  const innerColor = '#df1313';
  const buttonColor = '#df1313';

  return (
    <section className={'production section'} style={{ backgroundColor: accentColor}} id={`#${anchor.toLowerCase()}`}>
      <h2 className={'title'}>{title}</h2>
      <div className={'section_inner'} style={{ backgroundColor: innerColor}}>
        <h6 className={'subtitle'}>{subtitle}</h6>
        <div className={'description'}>
          {parseBodyText(description)}
        </div>

        <div className={'production-show'}>

          <Image tails={'production-show_bg'} desktopImage={image.url} mobileImage={mobileImage.url} altText={imageAlt} />
          
          {/* <img className={'production-show_bg desktop-image'} src={pic} alt={'tour show'} /> */}
          {/* <img className={'production-show_bg mobile-image'} src={picMobile} alt={'tour show'} /> */}


          <Button url={buttonLink} buttonColor={buttonColor} buttonText={buttonText} altText={title} />

        </div>
      </div>
    </section>
  );
}

export default ProductionView;
